import React from 'react';
import ContactForm from './ContactForm';
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import '../styles/Contact.css';

const Contact = () => {
  return (
    <div className="contact-page">
      <h1>Contact Us</h1>
      <p>If you have any questions, or would like to book our services, feel free to reach out to us using the form below or through our contact details.</p>

      <div className="contact-container">
        {/* Contact Form Section */}
        <div className="contact-form-section">
          <ContactForm />
        </div>

        {/* Contact Information Section */}
        <div className="contact-info">
          <h2>Contact Information</h2>
          <p>Email: thegrandstopoverhotel@gmail.com</p>
          <p>Phone: 0705867004</p>
          <p>Address: viewpoint, flyover, along Nairobi-Nakuru highway</p>

          <h2>Our Location</h2>
          {/* Google Maps iframe */}
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d557.9441996320675!2d36.55592942255413!3d-0.872054901961761!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182929333230925d%3A0x29afa975e6291dc5!2sThe%20Grand%20Stopover!5e0!3m2!1sen!2ske!4v1731413616897!5m2!1sen!2ske"
            width="100%"
            height="300"
            frameBorder="0"
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
          ></iframe>

          {/* Social Media Links */}
          <div className="social-media">
            <h3>Follow Us:</h3>
            <div className="social-icons">
              <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                <FaFacebook size={30} />
              </a>
              <a href="https://x.com" target="_blank" rel="noopener noreferrer">
                <img
                    src="/Twitter X Rounded Icon.svg"
                    alt="Close Icon"
                    style={{ width: '50px', height: '50px', cursor: 'pointer', transition: 'filter 0.3s ease' }}
                    className="icon-image"
                    />
              </a>
              <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                <FaInstagram size={30} />
              </a>
              <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                <FaLinkedin size={30} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
